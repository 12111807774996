import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { EnvironmentService } from '@shared/environment.service';
import { ApiConfigType } from 'src/environments/environment.interface';
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { distinctUntilKeyChanged, map, Subject, takeUntil } from 'rxjs';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {

  /**
   * A reference to the main menu drawer
   */
  @ViewChild('mainMenuDrawer', {static: true})
  public menuDrawer!: MatDrawer;

  public signInURL: string | undefined;

  public signUpURL: string | undefined;

  private unsub: Subject<void> = new Subject();

  public isHandheld: boolean = false;
  
  public constructor(
    private meta: Meta,
    private environmentService: EnvironmentService,
    private breakpointsObserver: BreakpointObserver,
    @Inject(PLATFORM_ID) private platformID: Object
  ) { }


  /**
   * Init lifecycle hook to setup initial listeners and config.
   */
  public ngOnInit(): void {

    this.setupMetaTags();
    this.setupLinks();
    
    if(isPlatformBrowser(this.platformID)) {
      this.subscribeToBreakPoints();
    }

  }


  /**
   * Teardown lifecycle hooks
   */
  public ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }


  /**
   * Global meta tags for all app pages.
   */
  public setupMetaTags(): void {
    this.meta.addTags([
      {
        name: 'theme-color',
        content: '#0d47a1'
      },
      // {
      //   name: 'keywords',
      //   content: 'personal finance,budget,money,track,cash flow,bills,subscriptions,banking,financial goals'
      // },
      {
        property: 'og:site_name',
        content: 'Blue Cardinal'
      },
      {
        property: 'og:locale',
        content: 'en_US'
      },
      {
        property: 'og:type',
        content: 'website'
      },
      {
        property: 'og:logo',
        content: 'images/logo_blue_background_512px.png'
      },
    ]);

    if (!this.environmentService.isProduction()) {
      this.meta.addTag({
        name: 'robots',
        content: 'noindex, nofollow'
      });
    }
  }


  /**
   * Sets up sign-up and sign-in link URLs.
   */
  public setupLinks(): void {
    const baseURL = this.environmentService.getApiBaseURL(ApiConfigType.CUSTOMER_PORTAL);

    if (baseURL) {
      this.signInURL = `${baseURL}/login`;
      this.signUpURL = `${baseURL}/sign-up`
    }
  }


  /**
   * Listens to the layout breakpoints observer and
   * sets the {isHandheld} property.
   */
  public subscribeToBreakPoints(): void {
    this.breakpointsObserver
      .observe([Breakpoints.Handset])
      .pipe(
        takeUntil(this.unsub),
        distinctUntilKeyChanged('matches'),
        map(state => this.isHandheld = state.matches)
      ).subscribe();
  }


  /**
   * Opens the main menu drawer.
   */
  public openMenu(): void {
    this.menuDrawer?.open();
  }


  /**
   * Closes the main menu drawer.
   */
  public closeMenu(): void {
    this.menuDrawer.close();
  }

}
